import React, { useContext, useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField,
    MenuItem,
    Button,
    Menu,
    CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { BranchContext } from '../../Context/BrancheContext';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TableBranch() {
    const { branchData, fetchBranchData, deleteBranch } = useContext(BranchContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [userGroup, setUserGroup] = useState(null);
    const [currentBranchId, setCurrentBranchId] = useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    // const testingPdfGenerationFromAmplifyApi = async () => {
    //     try {
    //         const response = await API.get("apiPdfGenerator", "/pdf/test");
    //         console.log(`response ${JSON.stringify(response)}`);
    //     } catch (error) {
    //         console.error(`error in post apiPdfGenerator/pdf ${error.message}`);
    //         console.log(error);
    //     }
    // };

    // testingPdfGenerationFromAmplifyApi();

    useEffect(() => {
        const fetchUserGroup = async () => {
            try {
                const session = await Auth.currentSession();
                const groups = session.getIdToken().payload['cognito:groups'] || [];
                if (groups.length) {
                    setUserGroup(groups[0]);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du groupe de l'utilisateur: ", error);
            }
        };
        fetchUserGroup();
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    };

    const handleEditBranch = (e, branch) => {
        navigate(`/updateBranche/${branch.id}`, { state: { branch: branch } });
    };

    const handleDeleteBranch = async (id) => {
        console.log('ID de la branche à supprimer :', id);
        handleClose();
        if (userGroup !== 'ADMIN') {
            toast.error('Vous ne pouvez pas supprimer cette branche. Veuillez contacter votre administrateur.');
            return;
        }

        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette branche ?')) {
            setLoading(true);
            try {
                await deleteBranch(id);
                setLoading(false);
                toast.success('Branche supprimée avec succès!', {
                    onClose: () => {
                        fetchBranchData();
                    }
                });
            } catch (error) {
                setLoading(false);
                console.error('Erreur lors de la suppression de la branche : ', error);
                toast.error('Erreur lors de la suppression de la branche.');
            }
        } else {
            console.log('Suppression annulée.');
        }
    };

    useEffect(() => {
        fetchBranchData();
    }, []);

    const displayedBranches =
        branchData && branchData.length > 0
            ? branchData
                  .filter((branch) => {
                      const searchRegex = new RegExp(searchText, 'i');
                      return (
                          searchRegex.test(branch.nomBranche) ||
                          searchRegex.test(
                              branch.formules && branch.formules.items
                                  ? Math.max(
                                        ...branch.formules.items.map((formule) => formule.options.length)
                                    ).toString()
                                  : ''
                          ) ||
                          searchRegex.test(branch.formules ? branch.formules.items.length.toString() : '')
                      );
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : [];

    return (
        <div>
            {loading ? (
                <div className="loading-container">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 0' }}>
                        <TextField
                            label="Rechercher"
                            variant="outlined"
                            size="small"
                            value={searchText}
                            onChange={handleSearchTextChange}
                        />
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="branch table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Branche</TableCell>
                                        <TableCell>Option</TableCell>
                                        <TableCell>Formule</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayedBranches.map((branch) => (
                                        <TableRow key={branch.id}>
                                            <TableCell>{branch.nomBranche}</TableCell>
                                            <TableCell>
                                                {branch.formules && branch.formules.items
                                                    ? Math.max(
                                                          ...branch.formules.items.map(
                                                              (formule) => formule.options.items.length
                                                          )
                                                      )
                                                    : 0}
                                            </TableCell>
                                            <TableCell>{branch.formules ? branch.formules.items.length : 0}</TableCell>
                                            <TableCell>
                                                <MenuItem
                                                    id={'edit-menu-' + branch.id}
                                                    onClick={(e) => handleEditBranch(e, branch)}
                                                    style={{ width: '50%' }}
                                                >
                                                    <EditIcon />
                                                </MenuItem>
                                            </TableCell>
                                            <TableCell>
                                                <MenuItem
                                                    id={'delete-menu-' + branch.id}
                                                    onClick={() => handleDeleteBranch(branch.id)}
                                                    style={{ width: '50%' }}
                                                >
                                                    <DeleteIcon />
                                                </MenuItem>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <TablePagination
                        className="table-pagination"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={branchData ? branchData.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </>
            )}
        </div>
    );
}
