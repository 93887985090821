import React from "react";
import { Container} from "@mui/material";
import Navbar from "../../components/componentsAdmin/Navbar";
import "../../styles/stylesAdmin/AccountPage.css";
import TableauBranche from "../../components/componentsAdmin/TableBranch";


function BranchPage() {
  return (
    <div className="comptpage">
      <Navbar />
      <div className="comptpage-container">
        <div className="comptpage-table">
          <Container style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
              <TableauBranche />
            </Container>
        </div>
      </div>
    </div>
  );
}

export default BranchPage;